import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getAdminPlExposureCricketAPI,
  getAllMatchesOddsCricketAPI,
  getBetActiveDeactiveCricketAPI,
  getClientBetsCricketAPI,
  getCricketEventDetailsAPI,
  getCricketOddsByMatchIdAPI,
  getSettledFancyAPI,
} from "../service/cricket";
import {
  getLiveStreamingUrlAPI,
  getSportsScoreAPI,
  getBookMakerPositionAPI,
} from "../service/AuthService";
import moment from "moment";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

export default function MatchPosition() {
  const userData = useSelector((state) => state.account.userData);
  const [matchOdds, setMatchOdds] = useState(null);
  const [matchDetails, setMatchDetails] = useState(null);
  const location = useLocation();
  const [betFilter, setBetFilter] = useState("BOOKMAKER_ODDS_1");
  const [userBetStatus, setUserBetStatus] = useState(false);
  const [fancyBetStatus, setFancyBetStatus] = useState(false);
  const [settledFancy, setSettledFancy] = useState({});
  const [matchData, setMatchData] = useState(null);
  const [clientBets, setClientBets] = useState([]);
  const [betStatus, setBetStatus] = useState("MATCHED");
  const [bookmakerPosition, setBookmakerPosition] = useState([]);
  const [gameSettings_, setGameSettings_] = useState({});
  const [showLiveTv, setShowLiveTv] = useState(false);
  const [channelId, setChannelId] = useState(null);
  const [showLadder, setShowLadder] = useState(false);
  const [matches, setMatches] = useState([]);
  const getAllMatches = async () => {
    const { response } = await getAllMatchesOddsCricketAPI();
    setMatches(response);
  };

  useEffect(() => {
    getAllMatches();
  }, []);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  var gameType = "cricket";
  var eventId = params?.id;
  var gameSettings = useSelector(
    (state) => state.gameData[gameType + "Settings"]
  );
  if (gameSettings && gameSettings[eventId]) {
    gameSettings = gameSettings[eventId];
  }
  const scoreData = useQuery("scoreData", () => getSportsScoreAPI(eventId), {
    refetchInterval: 4000,
  });
  const getBookMakerposition = async (uid) => {
    const { response, code } = await getBookMakerPositionAPI(eventId, uid);
    if (code == 200) {
      setBookmakerPosition(response);
    }
  };

  const getGameEventDetails = useCallback(async () => {
    const { response } = await getCricketEventDetailsAPI(eventId);
    setMatchDetails(response);
    return response;
  }, [eventId, gameType]);
  const getSettledFancy = async () => {
    const { response } = await getSettledFancyAPI(eventId);
    setSettledFancy(response);
  };
  useEffect(() => {
    getGameEventDetails();

    setGameSettings_(gameSettings[eventId]);
  }, [location.pathname]);

  const getCricketPlExposure = async () => {
    const { response, code } = await getAdminPlExposureCricketAPI(eventId);
    if (code == 200) {
      setMatchData(response);
    }
  };
  const getClientBets = async (betStatus_, isFancy) => {
    const { response } = await getClientBetsCricketAPI(
      eventId,
      isFancy ? isFancy : params?.runnerName ? true : false,
      betStatus_ ? betStatus_ : betStatus
    );
    if (response) {
      setClientBets(response);
    }
  };
  const getBetActiveDeactive = async () => {
    const { response } = await getBetActiveDeactiveCricketAPI(eventId);
    if (response) {
      setUserBetStatus(response?.betLock);
      setFancyBetStatus(response?.fancyLock);
    }
  };
  const getCricketOddsByMatchId = useCallback(async () => {
    const { response, code } = await getCricketOddsByMatchIdAPI(eventId);
    if (code != 200) {
      toast.error("Something went wrong");
      window.location.href = "/";
      return;
    }
    let response_ = {};
    response_[eventId] = response;
    setMatchOdds(response);
    dispatch({
      type: "gameData/setCricketOdds",
      payload: response_,
    });
  }, [eventId]);

  useEffect(() => {
    if (eventId) {
      getCricketPlExposure();
      getClientBets();
      let interval = setInterval(() => {
        getCricketPlExposure();
        getClientBets();
        getBetActiveDeactive();
        getSettledFancy();
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [eventId]);
  useEffect(() => {
    let interval = setInterval(() => {
      getCricketOddsByMatchId();
    }, 800);
    return () => clearInterval(interval);
  }, [eventId]);

  const getLiveStreamingUrl = async () => {
    let gameId = await getGameEventDetails();
    const { response } = await getLiveStreamingUrlAPI(gameId?.eventId);
    setChannelId(response);
  };
  useEffect(() => {
    getLiveStreamingUrl();
  }, [eventId]);

  return (
    <div _ngcontent-ng-c3705319800="" className="main_content">
      <router-outlet _ngcontent-ng-c3705319800="" />
      <app-match-detail
        _nghost-ng-c2652263913=""
        className="ng-star-inserted"
      >
        <div _ngcontent-ng-c2652263913="" className="page_header">
          <h1 _ngcontent-ng-c2652263913="" className="page_heading">
            Match &amp; Session Position
          </h1>
          <div _ngcontent-ng-c2652263913="" className="breadcrumb_block">
            <nav _ngcontent-ng-c2652263913="" aria-label="breadcrumb">
              <ol _ngcontent-ng-c2652263913="" className="breadcrumb">
                <li _ngcontent-ng-c2652263913="" className="breadcrumb-item">
                  <a _ngcontent-ng-c2652263913="" href="#">
                    Home
                  </a>
                </li>

                <li
                  _ngcontent-ng-c2652263913=""
                  aria-current="page"
                  className="breadcrumb-item active"
                >
                  Match &amp; Session Position
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div
          _ngcontent-ng-c2652263913=""
          className="Super_master_details_section agentledger_block match_details_mobile"
        >
          <div _ngcontent-ng-c2652263913="" className="match_detail_header">
            <h5 _ngcontent-ng-c2652263913="">{matchDetails?.eventName} </h5>
            <div _ngcontent-ng-c2652263913="" className="dtl_headr">
              <button
                _ngcontent-ng-c2652263913=""
                onClick={() => setShowLiveTv(!showLiveTv)}
                className="btn btn-primary live_tv_btn"
              >
                Live TV
              </button>
              <div _ngcontent-ng-c2652263913="" className="dropdown">
                <a
                  _ngcontent-ng-c2652263913=""
                  href="#"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="btn btn-secondary dropdown-toggle"
                >
                  {" "}
                  Matches{" "}
                </a>
                <ul
                  _ngcontent-ng-c2652263913=""
                  aria-labelledby="dropdownMenuLink"
                  className="dropdown-menu"
                >
                  {matches
                    ?.filter((item) => item?.inPlay)
                    ?.map((item) => (
                      <li
                        _ngcontent-ng-c2652263913=""
                        className="dropdown-item"
                        onClick={() => {
                          navigate(`/game/matchPosition/${item?.cricketId}`);
                        }}
                      >
                        {item?.eventName}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          {showLiveTv && (
            <div _ngcontent-ng-c2652263913="" class="toggletv">
              <iframe
                _ngcontent-ng-c2652263913=""
                id="cricketScore"
                title="Match Score"
                class="iframestyle iframeheightControll"
                style={{ width: "100%", height: "300px", background: "#000" }}
                src={`https://video.proexch.in/tv/static?chid=${channelId}`}
              />
            </div>
          )}
          <div
            _ngcontent-ng-c2652263913=""
            className="scoreboard ng-star-inserted"
          >
            <iframe
              style={{
                padding: "0 10px",
                width: "100%",
              }}
              src={`https://score.crickexpo.in/socket-iframe-1/${
                matchDetails?.eventId
              }`}
            />
          </div>
          {/**/}
          {/**/}
          {/**/}
          <div _ngcontent-ng-c2652263913="" className="row">
            <div
              _ngcontent-ng-c2652263913=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div
                _ngcontent-ng-c2652263913=""
                className="custom_table bet_tbl"
              >
                <span
                  _ngcontent-ng-c2652263913=""
                  className="page_heading ng-star-inserted"
                >
                  Bookmaker
                </span>
                <table
                  _ngcontent-ng-c2652263913=""
                  className="table ng-star-inserted"
                >
                  <thead _ngcontent-ng-c2652263913="">
                    <tr _ngcontent-ng-c2652263913="">
                      <th _ngcontent-ng-c2652263913="">TEAM</th>
                      <th
                        _ngcontent-ng-c2652263913=""
                        className="td_width text-center"
                      >
                        LAGAI
                      </th>
                      <th
                        _ngcontent-ng-c2652263913=""
                        className="td_width text-center"
                      >
                        KHAI
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c2652263913="">
                    {matchOdds &&
                      matchOdds?.bookMakerOdds[0]?.bm1?.oddDetailsDTOS.map(
                        (match, index) => (
                          <tr
                            _ngcontent-ng-c2652263913=""
                            className="ng-star-inserted"
                          >
                            <td
                              _ngcontent-ng-c2652263913=""
                              className="position-relative"
                            >
                              <a
                                _ngcontent-ng-c2652263913=""
                                href="javascript:void(0)"
                                className="team_name_link font_gridDisplay"
                              >
                                <div _ngcontent-ng-c2652263913="">
                                  <span
                                    _ngcontent-ng-c2652263913=""
                                    className="d-block"
                                  >
                                    {match?.runnerName}
                                  </span>
                                  <span
                                    _ngcontent-ng-c2652263913=""
                                    className={`d-block ${
                                      parseFloat(
                                        matchData
                                          ?.find(
                                            (item) =>
                                              item.marketIdExternal ==
                                              matchOdds?.bookMakerOdds[0]?.bm1
                                                ?.marketId
                                          )
                                          ?.runnerPlExposureDTOS?.find(
                                            (pl) =>
                                              pl.selectionId ==
                                              match?.selectionId
                                          )?.exposure
                                      ) > 0
                                        ? "plus_value"
                                        : "mainus_value"
                                    }`}
                                  >
                                    {parseFloat(
                                      matchData
                                        ?.find(
                                          (item) =>
                                            item.marketIdExternal ==
                                            matchOdds?.bookMakerOdds[0]?.bm1
                                              ?.marketId
                                        )
                                        ?.runnerPlExposureDTOS?.find(
                                          (pl) =>
                                            pl.selectionId ==
                                            match?.selectionId
                                        )?.exposure
                                    )}
                                  </span>
                                </div>
                              </a>
                              <div
                                _ngcontent-ng-c2652263913=""
                                className="market_minmax"
                              >
                                <a
                                  _ngcontent-ng-c2652263913=""
                                  href="javascript:void(0)"
                                  className="team_name_link font_gridDisplay"
                                />
                                <div
                                  _ngcontent-ng-c2652263913=""
                                  className="dropdown"
                                >
                                  <a
                                    _ngcontent-ng-c2652263913=""
                                    href="javascript:void(0)"
                                    className="team_name_link font_gridDisplay"
                                  />
                                  <a
                                    _ngcontent-ng-c2652263913=""
                                    role="button"
                                    id="dropdownMenuLink1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className="btn btn-secondary dropdown-toggle"
                                  >
                                    {" "}
                                    i{" "}
                                  </a>
                                  <ul
                                    _ngcontent-ng-c2652263913=""
                                    aria-labelledby="dropdownMenuLink1"
                                    className="dropdown-menu"
                                  >
                                    <div
                                      _ngcontent-ng-c2652263913=""
                                      className="value_min_max"
                                    >
                                      <span _ngcontent-ng-c2652263913="">
                                        Min:100
                                      </span>
                                      <span _ngcontent-ng-c2652263913="">
                                        Max:200000.00
                                      </span>
                                    </div>
                                  </ul>
                                </div>
                              </div>
                            </td>
                            <td
                              _ngcontent-ng-c2652263913=""
                              colSpan={2}
                              className="position-relative"
                            >
                              {/*  <div
                                _ngcontent-ng-c2652263913=""
                                className="suspend_block ng-star-inserted"
                              >
                                <span _ngcontent-ng-c2652263913="">
                                  {" "}
                                  Suspend{" "}
                                </span>
                              </div> */}
                              {/**/}
                              <table
                                _ngcontent-ng-c2652263913=""
                                className="inner_tbl"
                              >
                                <tbody>
                                  <tr _ngcontent-ng-c2652263913="">
                                    <td _ngcontent-ng-c2652263913="">
                                      <a
                                        _ngcontent-ng-c2652263913=""
                                        href="javascript:void(0)"
                                        onclick="AddMatchBitL(1)"
                                        className="back btn"
                                        style={{
                                          height: "50px",
                                          marginBottom: 0,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span _ngcontent-ng-c2652263913="">
                                          {match?.status == "ACTIVE"
                                            ? parseFloat(
                                                match?.back1
                                              ).toFixed(2)
                                            : 0}
                                        </span>
                                        <span
                                          _ngcontent-ng-c2652263913=""
                                          className="Odds_size"
                                        >
                                          {match?.status == "ACTIVE"
                                            ? parseFloat(
                                                match?.backSize1 / 100
                                              ).toFixed(2)
                                            : 0}
                                        </span>
                                      </a>
                                    </td>
                                    <td _ngcontent-ng-c2652263913="">
                                      <a
                                        _ngcontent-ng-c2652263913=""
                                        href="javascript:void(0)"
                                        onclick="AddMatchBitK(1)"
                                        className="lay btn"
                                        style={{
                                          height: "50px",
                                          marginBottom: 0,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span _ngcontent-ng-c2652263913="">
                                          {match?.status == "ACTIVE"
                                            ? parseFloat(match?.lay1).toFixed(
                                                2
                                              )
                                            : 0}
                                        </span>
                                        <span
                                          _ngcontent-ng-c2652263913=""
                                          className="Odds_size"
                                        >
                                          {match?.status == "ACTIVE"
                                            ? parseFloat(
                                                match?.laySize1 / 100
                                              ).toFixed(2)
                                            : 0}
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
                {/**/}
                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c2652263913=""
              className="col-sm-12 col-md-12 col-lg-12 col-xxl-6"
            >
              <div
                _ngcontent-ng-c2652263913=""
                className="custom_table bet_tbl session_fancy_bet_table ng-star-inserted"
              >
                <table _ngcontent-ng-c2652263913="" className="table">
                  <thead _ngcontent-ng-c2652263913="">
                    <tr _ngcontent-ng-c2652263913="">
                      <th _ngcontent-ng-c2652263913="">SESSION</th>
                      <th
                        _ngcontent-ng-c2652263913=""
                        width="110px"
                        className="text-center bet_table_head"
                      >
                        NO
                      </th>
                      <th
                        _ngcontent-ng-c2652263913=""
                        width="110px"
                        className="text-center bet_table_head"
                      >
                        YES
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c2652263913="">
                    {matchOdds?.fancyOdds
                      ?.find(
                        (item) =>
                          item?.gameType?.toLocaleLowerCase() == "normal"
                      )
                      ?.oddDetailsDTOS?.map((match) => (
                        <>
                          {" "}
                          <tr
                            _ngcontent-ng-c2652263913=""
                            className="ng-star-inserted"
                          >
                            <td
                              _ngcontent-ng-c2652263913=""
                              className="position-relative"
                            >
                              <a
                                _ngcontent-ng-c2652263913=""
                                href="javascript:void(0)"
                                className="team_name_link font_gridDisplay fancy_st_padding"
                              >
                                <span _ngcontent-ng-c2652263913="">
                                  {match?.runnerName}
                                </span>
                              </a>
                              <span
                                _ngcontent-ng-c2652263913=""
                                className="d-block ps-2"
                              >
                                <span
                                  _ngcontent-ng-c2652263913=""
                                  className={
                                    matchData
                                      ?.find((item) =>
                                        item.marketIdExternal.includes("F")
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (pl) =>
                                          pl.selectionId == match?.selectionId
                                      )?.exposure >= 0
                                      ? "plus_value"
                                      : "mainus_value"
                                  }
                                >
                                  {" "}
                                  {parseFloat(
                                    matchData
                                      ?.find((item) =>
                                        item.marketIdExternal.includes("F")
                                      )
                                      ?.runnerPlExposureDTOS?.find(
                                        (pl) =>
                                          pl.selectionId == match?.selectionId
                                      )?.exposure
                                      ? matchData
                                          ?.find((item) =>
                                            item.marketIdExternal.includes(
                                              "F"
                                            )
                                          )
                                          ?.runnerPlExposureDTOS?.find(
                                            (pl) =>
                                              pl.selectionId ==
                                              match?.selectionId
                                          )?.exposure
                                      : 0
                                  ).toFixed(2)}
                                </span>
                              </span>
                              <div
                                _ngcontent-ng-c2652263913=""
                                className="market_minmax bet_btn"
                              >
                                <div
                                  _ngcontent-ng-c2652263913=""
                                  className="dropdown"
                                >
                                  <a
                                    _ngcontent-ng-c2652263913=""
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                    className="btn btn-secondary dropdown-toggle"
                                    id="dropdownMenuLink0"
                                  >
                                    {" "}
                                    i{" "}
                                  </a>
                                  <ul
                                    _ngcontent-ng-c2652263913=""
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuLink0"
                                  >
                                    <div
                                      _ngcontent-ng-c2652263913=""
                                      className="value_min_max"
                                    >
                                      <span _ngcontent-ng-c2652263913="">
                                        Min: 100
                                      </span>
                                      <span _ngcontent-ng-c2652263913="">
                                        Max: 50000.00
                                      </span>
                                    </div>
                                  </ul>
                                </div>
                                <a
                                  _ngcontent-ng-c2652263913=""
                                  onClick={() => {
                                    setShowLadder(match);
                                  }}
                                  type="button"
                                  className="bet_btn1"
                                >
                                  <img
                                    _ngcontent-ng-c2652263913=""
                                    alt=""
                                    src="https://ik.imagekit.io/bmaxmbpyx/https://admin.jmd15.com/assets/img/fancy-stayers.webp"
                                  />
                                </a>
                              </div>
                            </td>
                            <td
                              _ngcontent-ng-c2652263913=""
                              colSpan={2}
                              className="position-relative p-0"
                            >
                              {/* <div
                                _ngcontent-ng-c2652263913=""
                                className="suspend_block"
                              >
                                <span _ngcontent-ng-c2652263913="">
                                  {" "}
                                  SUSPENDED{" "}
                                </span>
                              </div> */}
                              <table
                                _ngcontent-ng-c2652263913=""
                                className="inner_tbl_2 position-relative"
                              >
                                <tbody>
                                  <tr
                                    _ngcontent-ng-c2652263913=""
                                    className="position-relative"
                                  >
                                    <td
                                      _ngcontent-ng-c2652263913=""
                                      width="110px"
                                      className="p-0 bet_table_head"
                                    >
                                      <a
                                        _ngcontent-ng-c2652263913=""
                                        href="javascript:void(0)"
                                        className="lay btn"
                                        style={{
                                          height: "50px",
                                          marginBottom: 0,
                                        }}
                                      >
                                        <h5 _ngcontent-ng-c2652263913="">
                                          {parseInt(match?.lay1)}{" "}
                                        </h5>
                                        <span _ngcontent-ng-c2652263913="">
                                          {parseFloat(
                                            match?.laySize1 / 100
                                          ).toFixed(2)}
                                        </span>
                                      </a>
                                    </td>
                                    <td
                                      _ngcontent-ng-c2652263913=""
                                      width="110px"
                                      className="p-0 bet_table_head"
                                    >
                                      <a
                                        _ngcontent-ng-c2652263913=""
                                        href="javascript:void(0)"
                                        className="back btn"
                                        style={{
                                          height: "50px",
                                          marginBottom: 0,
                                        }}
                                      >
                                        <h5 _ngcontent-ng-c2652263913="">
                                          {parseInt(match?.back1)}{" "}
                                        </h5>
                                        <span _ngcontent-ng-c2652263913="">
                                          {parseFloat(
                                            match?.backSize1 / 100
                                          ).toFixed(2)}
                                        </span>
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          {match?.remark && (
                            <tr>
                              <marquee>{match?.remark}</marquee>
                            </tr>
                          )}
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
              {/**/}
            </div>
          </div>
          <div
            _ngcontent-ng-c2652263913=""
            className="custom_table matchdtl_bottm"
          >
            <div
              _ngcontent-ng-c2652263913=""
              className="table-responsive ng-star-inserted"
            >
              <table _ngcontent-ng-c2652263913="" className="table">
                <thead _ngcontent-ng-c2652263913="">
                  <tr _ngcontent-ng-c2652263913="">
                    <th _ngcontent-ng-c2652263913="">SNo</th>
                    <th _ngcontent-ng-c2652263913="">Rate</th>
                    {params?.runnerName && (
                      <th _ngcontent-ng-c2652263913="">Run</th>
                    )}
                    <th _ngcontent-ng-c2652263913="">Amount</th>
                    <th _ngcontent-ng-c2652263913="">Mode</th>
                    <th _ngcontent-ng-c2652263913="">Team</th>
                    <th _ngcontent-ng-c2652263913="">Client</th>
                    <th _ngcontent-ng-c2652263913="">Agent</th>
                    <th _ngcontent-ng-c2652263913="">Potential Profit</th>
                    <th _ngcontent-ng-c2652263913="">Liability</th>

                    <th _ngcontent-ng-c2652263913="">Date &amp; Time</th>
                    <th
                      _ngcontent-ng-c2652263913=""
                      className="ng-star-inserted"
                    >
                      Action{" "}
                    </th>
                    {/**/}
                  </tr>
                </thead>
                <tbody _ngcontent-ng-c2652263913="">
                  {/**/}
                  {clientBets?.bets
                    ?.filter((item) => {
                      if (params?.runnerName) {
                        return item?.runnerName == params?.runnerName;
                      } else if (betFilter) {
                        if (betFilter == "BOOKMAKER_ODDS_1")
                          return item?.marketName == betFilter;
                        else return item?.marketName != "BOOKMAKER_ODDS_1";
                      } else {
                        return item;
                      }
                    })
                    .map((item, index) => (
                      <>
                        <tr
                          className={item?.back ? "back" : "lay"}
                          key={index}
                        >
                          <td>{index + 1}</td>

                          <td>{item.rate}</td>
                          {params?.runnerName && <td>{item?.run}</td>}

                          <td>{parseFloat(item.amount).toFixed(2)}</td>
                          <td>
                            {params?.runnerName
                              ? item?.back
                                ? "LAGAI"
                                : "KHAI"
                              : item?.back
                              ? "YES"
                              : "NO"}
                          </td>
                          <td
                            onClick={() =>
                              (window.location.href = `/game/matchPosition/${
                                item?.eventId
                              }/${item?.runnerName}/${item?.marketId}`)
                            }
                          >
                            {item.runnerName}
                          </td>
                          <td>{item.userName}</td>

                          {item?.upperLineExposure?.map((item) => (
                            <td>
                              {Math.abs(
                                parseFloat(item?.exposure).toFixed(2)
                              )}
                            </td>
                          ))}
                          <td>
                            {moment(item.placeDate).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  <tr
                    _ngcontent-ng-c2652263913=""
                    style={{ textAlign: "center" }}
                    className="ng-star-inserted"
                  >
                    <td _ngcontent-ng-c2652263913="" colSpan={11}>
                      No Records Found
                    </td>
                  </tr>
                  {/**/}
                  {/**/}
                </tbody>
              </table>
            </div>
            {/**/}
            {/**/}
            {/**/}
          </div>
        </div>
        {/**/}
      </app-match-detail>
      <div
        _ngcontent-ng-c2652263913=""
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        className="modal fade show"
        aria-modal="true"
        role="dialog"
        style={{ display: !showLadder ? "none" : "block" }}
      >
        <div
          _ngcontent-ng-c2652263913=""
          className="modal-dialog modal-dialog-centered"
        >
          <div _ngcontent-ng-c2652263913="" className="modal-content">
            <div _ngcontent-ng-c2652263913="" className="modal-header">
              <h1
                _ngcontent-ng-c2652263913=""
                id="exampleModalLabel"
                className="modal-title fs-5"
              >
                {showLadder?.runnerName}
              </h1>
              <button
                _ngcontent-ng-c2652263913=""
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
                className="btn-close"
                onClick={() => setShowLadder(false)}
              />
            </div>
            <div _ngcontent-ng-c2652263913="" className="modal-body">
              <div _ngcontent-ng-c2652263913="" className="custom_table">
                <table
                  _ngcontent-ng-c2652263913=""
                  className="table table-bordered"
                >
                  <thead _ngcontent-ng-c2652263913="">
                    <tr _ngcontent-ng-c2652263913="">
                      <th _ngcontent-ng-c2652263913="" scope="col">
                        Run
                      </th>
                      <th _ngcontent-ng-c2652263913="" scope="col">
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c2652263913="">
                    {matchData &&
                      matchData
                        ?.find(
                          (item) =>
                            item.marketIdExternal == showLadder?.marketId
                        )
                        ?.runnerPlExposureDTOS?.sort(
                          (a, b) =>
                            parseInt(a.selectionName) -
                            parseInt(b.selectionName)
                        )
                        ?.map((item) => (
                          <tr _ngcontent-ng-c2652263913="">
                            <td _ngcontent-ng-c2652263913="">
                              {item?.selectionName}
                            </td>
                            <td
                              _ngcontent-ng-c2652263913=""
                              style={{
                                color: item?.exposure < 0 ? "red" : "green",
                              }}
                            >
                              {parseFloat(item?.exposure).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div _ngcontent-ng-c2652263913="" className="modal-footer">
              <button
                _ngcontent-ng-c2652263913=""
                type="button"
                onClick={() => setShowLadder(false)}
                data-bs-dismiss="modal"
                className="btn btn-primary"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
